<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mb-4">
          <v-card-title class="headline">회계관리</v-card-title>
          <v-card-subtitle>이체건수와 금융수수료집금을 관리할 수 있습니다.</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs v-model="activeTab" background-color="primary" dark>
            <v-tab>이체건수수</v-tab>
            <v-tab>금융수수료집금</v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <FileCollection />
            </v-tab-item>
            <v-tab-item>
              <TransferList />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileCollection from './accounting/FileCollection.vue'
import TransferList from './accounting/TransferList.vue'

export default {
  name: 'Accounting',
  components: {
    FileCollection,
    TransferList,
  },
  data() {
    return {
      activeTab: 0
    }
  },
  created() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'myfinbiz/login' })
    }
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters['myfinbiz/auth/isAuthenticated']
    }
  }
}
</script>
