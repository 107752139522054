<!-- src/pages/myfinbiz/accounting/FileCollection.vue -->
<template>
    <v-container fluid>
      <!-- 검색 필터 -->
      <v-card class="mb-4">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col>
                  <v-menu
                    ref="startMenu"
                    v-model="startMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.startDate"
                        label="시작일"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      />
                    </template>
                    <v-date-picker
                      v-model="filters.startDate"
                      no-title
                      @input="startMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.endDate"
                        label="종료일"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      />
                    </template>
                    <v-date-picker
                      v-model="filters.endDate"
                      no-title
                      @input="endMenu = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="3">
              <v-row>
                <v-col>
                  <v-btn small @click="setDate('today')">당일</v-btn>
                </v-col>
                <v-col>
                  <v-btn small @click="setDate('week')">일주일</v-btn>
                </v-col>
                <v-col>
                  <v-btn small @click="setDate('month')">1개월</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="filters.keyword"
                label="검색어"
                append-icon="mdi-magnify"
                clearable
                @keyup.enter="search"
                dense
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-btn color="primary" @click="search">검색</v-btn>
            </v-col>
            <v-col cols="12" sm="3">
              <v-btn @click="clearParams">초기화</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- 데이터 테이블 -->
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [15, 25, 50, 200, 1000]
        }"
      >
      </v-data-table>
    </v-container>
</template>
<script>
import dayjs from 'dayjs'

export default {
  name: 'FeeCollection',

  data() {
    return {
      loading: false,
      items: [],
      totalItems: 0,
      startMenu: false,
      endMenu: false,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['date'],
        sortDesc: [true]
      },
      headers: [
        // 나중에 API 연동시 헤더 추가
      ],
      filters: {
        keyword: '',
        startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
    }
  },

  methods: {
    setDate(type) {
      this.filters.endDate = dayjs().format('YYYY-MM-DD')
      if (type === 'today') {
        this.filters.startDate = this.filters.endDate
      } else {
        this.filters.startDate = dayjs().subtract(1, type).format('YYYY-MM-DD')
      }
      this.search()
    },

    search() {
      this.options.page = 1
      // API 연동 후 구현
    },

    clearParams() {
      this.filters = {
        keyword: '',
        startDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
      this.search()
    }
  }
}
</script>
